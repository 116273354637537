import { createStore as reduxCreateStore } from "redux"

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SEARCH_RESULTS':
			return { ...state,
				terms: action.terms,
				results: action.results,
			};
		
		case 'RESET_ON_NAVIGATE':
			state.terms = '';
			state.results = [];
			return state;

		default:
			return state;
	}
};

const initialState = {
	terms: '',
	results: [],
};

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore